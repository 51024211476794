import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function ApplicationModernization() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              {" "}
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              application modernization
            </label>
            <h4 className="mb-0">
              Future-Proof Your Applications with Amazon EKS and ECS
              Modernization Solutions
            </h4>
            <p>Transform, Modernize, and Scale Your Legacy Applications</p>
            <a href="/contact/schedule-consultation"  className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      Cruxito offers tailored modernization solutions leveraging
                      <strong>Amazon Elastic Kubernetes Service (EKS)</strong>
                      and
                      <strong>
                        {" "}
                        Amazon Elastic Container Service (ECS)
                      </strong>{" "}
                      to help your business stay ahead in a fast-evolving
                      digital landscape.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/app-banner.png")}
                      alt="Why Cruxito"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <h2>
                  Our services focus on seamlessly migrating and optimizing your
                  legacy applications into resilient, agile, and scalable
                  microservices-based architectures.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Why Choose Cruxito?</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Certified Expertise</h6>
                        <p>
                          Our AWS-certified professionals bring deep knowledge
                          of containerization, cloud-native architectures, and
                          application modernization.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Tailored Solutions: </h6>
                        <p>
                          We customize our modernization plans to align with
                          your business goals, ensuring a smooth transition.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>End-to-End Support: </h6>
                        <p>
                          From discovery to deployment, we manage the entire
                          lifecycle of your application modernization.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Process</h4>
            <p className="des">
              We follow a phased approach to ensure every modernization project
              is executed efficiently:
            </p>
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Discovery:</h6>
                  <ul>
                    <li>
                      Assess legacy systems and define modernization goals.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> System Assessment Report,
                      Modernization Roadmap.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Design</h6>
                  <ul>
                    <li>
                      Architect the target environment and plan containerization
                      strategies.
                    </li>
                    <li>
                      <strong>Deliverables:</strong>Target Architecture Diagram,
                      Containerization Blueprint.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Proof of Concept:</h6>
                  <ul>
                    <li>Validate configurations and demonstrate feasibility</li>
                    <li>
                      <strong>Deliverables:</strong> POC Demo, Validation
                      Report.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Development:</h6>
                  <ul>
                    <li>
                      Containerize applications and configure EKS/ECS clusters.
                    </li>
                    <li>
                      <strong>Deliverables:</strong>Docker Images, Configured
                      Environments, Automated CI/CD Pipelines
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Testing</h6>
                  <ul>
                    <li>Conduct functional and performance testing.</li>
                    <li>
                      <strong>Deliverables:</strong> Testing Report, Optimized
                      Configuration Documentation.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Deployment</h6>
                  <ul>
                    <li>Deploy to production with continuous monitoring.</li>
                    <li>
                      <strong>Deliverables:</strong> Production-Ready
                      Applications, Post-Deployment Monitoring Report.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-12">
                <div className="process-flow">
                  <h6>Handover</h6>
                  <ul>
                    <li>
                      Provide training, documentation, and knowledge transfer.
                    </li>
                    <li>
                      <strong>Deliverables:</strong>Training Materials,
                      Knowledge Transfer Certification.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="des">
              Our projects are typically completed in 30 to 40 weeks, depending
              on the complexity of your requirements.
            </p>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Key Benefits</h3>
                <p>With Cruxito’s expertise, organizations achieve:</p>
                <ul>
                  <li>
                    <strong>Scalability:</strong> Scale applications
                    effortlessly with Kubernetes and ECS's robust orchestration.
                  </li>
                  <li>
                    <strong>Cost Optimization:</strong> Reduce infrastructure
                    costs with efficient containerization and serverless
                    architectures.
                  </li>
                  <li>
                    <strong>Enhanced Performance:</strong> Accelerate
                    deployment, reduce downtime, and improve monitoring.
                  </li>
                  <li>
                    <strong>Future-Ready Architecture:</strong> Transition to
                    microservices for better agility and resilience
                  </li>
                  <li>
                    <strong>Comprehensive Security:</strong> Benefit from AWS’s
                    advanced security features like IAM roles, VPC integration,
                    and secrets management.
                  </li>
                  <li>
                    <strong>Automated CI/CD Integration:</strong> Implement
                    seamless continuous integration and delivery pipelines.
                  </li>
                  <li>
                    <strong>Reduced Overhead:</strong> Leverage managed services
                    to simplify infrastructure management.
                  </li>
                </ul>
              </div>
              <figure>
                <img
                  src={require("../images/app-cruxito.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Let’s Modernize Together</h3>
                <p>
                  eady to transform your legacy systems into modern, scalable
                  applications? Contact us today to get started with Cruxito’s
                  EKS and ECS Modernization Solutions.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <Contact></Contact>
    </React.Fragment>
  );
}
export default ApplicationModernization;
