import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Views/home";
import ContactPage from "../Views/ContactPage";
import StorageMigration from "../Views/StorageMigration";
import ManagedBackup from "../Views/ManagedBackup";
import DisasterRecovery from "../Views/DisasterRecovery";
import GenAiDeveloper from "../Views/GenAiDeveloper";
import GenAiBusiness from "../Views/​GenAiBusiness";
import GenAiBot from "../Views/​GenAiBot";
import ApplicationModernization from "../Views/ApplicationModernization";

function Router() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/contact/:tag" element={<ContactPage />} />
        <Route path="/storage-migration" element={<StorageMigration />}></Route>
        <Route path="/managed-backup" element={<ManagedBackup />}></Route>
        <Route path="/managed-disaster-recovery" element={<DisasterRecovery />}></Route>
        <Route path="/gen-ai-developer" element={<GenAiDeveloper />}></Route>
        <Route path="/gen-ai-business" element={<GenAiBusiness />}></Route>
        <Route path="/gen-ai-chatbot" element={<GenAiBot />}></Route>
        <Route
          path="/application-modernization"
          element={<ApplicationModernization />}
        ></Route>
      </Routes>
    </React.Fragment>
  );
}
export default Router;
