//import logo from './logo.svg';
import React from 'react';
import Router from "./Router/Router";
import Header from "./Views/Header"; 
import Footer from "./Views/Footer";
import './Styles/Styles.scss';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <div className="layout">
        <Header />
        <Router />
        <Footer />
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
