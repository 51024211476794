import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function ManagedBackup() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              {" "}
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              Managed
            </label>
            <h4>Safeguard Your Business with Seamless Disaster Recovery</h4>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      At Cruxito Tech Solutions, we specialize in simplifying
                      and automating your data backup and recovery processes
                      with our &nbsp;
                      <strong>
                        “Secure, Reliable & Managed Data Protection Using AWS
                        Backup” &nbsp;
                      </strong>
                      service.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/managed-banner.png")}
                      alt="Why Cruxito"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <h2>
                  We leverage AWS Backup to deliver a centralized, scalable, and
                  secure solution that ensures your data is protected and always
                  accessible when you need it the most.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Why Choose Cruxito?</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Centralized Backup Management</h6>
                        <p>
                          Simplify your backup processes with unified policies
                          and a single control pane across AWS services.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Improved Data Security</h6>
                        <p>
                          Your data stays protected with encryption at rest and
                          in transit, along with granular access control using
                          AWS Identity and Access Management (IAM).
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Automated and Consistent Backups</h6>
                        <p>
                          Say goodbye to manual processes with policy-driven
                          automation and consistent backup schedules.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>Compliance and Governance</h6>
                        <p>
                          Stay ahead of regulations like GDPR, HIPAA, and more,
                          with audit trails and retention policies.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Cost Optimization</h6>
                        <p>
                          Pay-as-you-go pricing ensures you only pay for what
                          you need, with optimized storage solutions that reduce
                          waste.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Seamless Integration and Recovery</h6>
                        <p>
                          Experience minimal downtime with rapid disaster
                          recovery and integration with a wide range of AWS
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Comprehensive Approach</h4>
            {/* <p className="des">
              Our managed service ensures a seamless experience, broken down
              into five key phases:
            </p> */}
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Planning and Assessment</h6>
                  <ul>
                    <li>
                      We analyze your current backup strategies, identify gaps,
                      and create a tailored project plan aligned with your
                      needs.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Solution Design</h6>
                  <ul>
                    <li>
                      Custom backup policies, IAM configurations, and AWS
                      services selected to fit your organization perfectly.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Implementation & Deployment</h6>
                  <ul>
                    <li>
                      From automated backups to integrated monitoring, we ensure
                      a seamless setup.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="process-flow">
                  <h6>Validation & Optimization</h6>
                  <ul>
                    <li>
                      Compliance validation, cost-efficiency fine-tuning, and
                      hands-on training for your team.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Proven Results in Every Engagement</h3>
                <p>With Cruxito’s expertise, organizations achieve:</p>
                <ul>
                  <li>
                    Increased resilience and reliability for their critical
                    data.
                  </li>
                  <li>Streamlined compliance with regulatory requirements.</li>
                  <li>
                    Significant savings with optimized backup configurations.
                  </li>
                </ul>
              </div>
              <figure>
                <img
                  src={require("../images/proven-results.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Your Trusted Partner in Data Protection</h3>
                <p>
                  Backed by a team of AWS-certified professionals, we deliver
                  end-to-end solutions tailored to meet the unique needs of
                  businesses across industries.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <div className="schedule-info">
        <h4 className="title">
          Ready to elevate your data protection strategy?
        </h4>
        <p>
          <strong>Contact us today to schedule your free consultation</strong>
          &nbsp; and learn how <a href="#">Cruxito</a> can transform your data
          backup and recovery processes.
        </p>
      </div>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default ManagedBackup;
