import React, { useState } from "react";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dept, setDept] = useState("");
  const [info, setInfo] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const submitToAPI = (e) => {
    e.preventDefault();

    const URL = "https://cruxito.tech/blog/wp-json/custom/v1/submit-form";

    if (!name || name.length < 2) {
      alert("Name cannot be empty and should be at least 2 characters long");
      return;
    }

    if (!email) {
      alert("Please enter your email id");
      return;
    }
    if (!dept) {
      alert("Please enter subject");
      return;
    }
    if (!info) {
      alert("Please enter message what you need");
      return;
    }

    const reEmail = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reEmail.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    const data = {
      name,
      email,
      dept: dept || "General Information Request",
      info,
      phone,
      tags: ["Contact Form"], // Add Mailchimp tag here
    };

    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(() => {
        alert("Form submitted successfully!");
        setName("");
        setEmail("");
        setDept("");
        setInfo("");
        setPhone("");
        setIsChecked(false);
      })
      .catch(() => {
        alert("Failed to submit the form.");
        setName("");
        setEmail("");
        setDept("");
        setInfo("");
        setPhone("");
        setIsChecked(false);
      });
  };

  return (
    <div className="contact-us_section" id="contact">
      <div className="container">
        <div className="contact_section">
          <h2>CONTACT US</h2>
          <div className="content">
            <p className="description">
              Engage us for roadmapping
              or execution services. We are now offering limited free
              consultations for early stage companies and small scales
              businesses.
            </p>
            <div className="details">
              <label>Phone</label>
              <a>+1 425-624-9820</a>
              <label>E-mail</label>
              <a href="mailto:admin@cruxito.tech">admin@cruxito.tech</a>
              <label>Location</label>
              <span>
                1700 Westlake Avenue N, Suite 200
                <br />
                PMB 0803
                <br />
                Seattle, WA 98109
              </span>
            </div>
          </div>
        </div>
        <div className="form_section">
          <h2>SEND MESSAGE</h2>
          <form onSubmit={submitToAPI}>
            <ul>
              <li className="twocol">
                <input
                  type="text"
                  placeholder="Name"
                  id="name-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email"
                  id="email-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </li>
              <li className="twocol">
                <input
                  type="text"
                  placeholder="Subject"
                  value={dept}
                  onChange={(e) => setDept(e.target.value)}
                />
                <input
                  placeholder="Phone No"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </li>
              <li>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Message"
                  value={info}
                  onChange={(e) => setInfo(e.target.value)}
                ></textarea>
              </li>
              <li>
                <div className="terms_check">
                  <input
                    type="checkbox"
                    id="phone"
                    name="vehicle1"
                    value="Bike"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <label htmlFor="phone">
                    By Providing your phone number, you agree to receive a text
                    message from Cruxito. Message and Data rates may apply.
                    Message frequency varies.
                  </label>
                </div>
              </li>
            </ul>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
