import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function GenAiDeveloper() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              {" "}
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              Gen AI Developer
            </label>
            <h4>
              Accelerate Your Software Development with Amazon Q Developer
            </h4>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      At Cruxito, we empower businesses to build smarter,
                      faster, and scalable applications by leveraging{" "}
                      <strong>Amazon Q Developer</strong>, a cutting-edge
                      AI-assisted development platform.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/Q_developer.png")}
                      alt="Why Cruxito"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <h2>
                  With this service, you’ll unlock faster software delivery,
                  enhanced code quality, and robust cloud-native applications
                  that reduce time-to-market and operational costs.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Our Service Highlights</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Accelerated Development</h6>
                        <p>
                          Utilize AI-assisted coding to shorten development
                          cycles and ensure timely project delivery.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Enhanced Code Quality</h6>
                        <p>
                          Ensure best practices with automated code reviews and
                          refactoring.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Increased Scalability</h6>
                        <p>
                          Build applications optimized for cloud-native
                          environments using scalable architectures.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>Seamless Debugging</h6>
                        <p>
                          Resolve issues faster with AI-powered debugging tools.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Cost-Effective Solutions</h6>
                        <p>
                          Streamline workflows and improve developer efficiency,
                          cutting operational costs.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Future-Proof Applications</h6>
                        <p>
                          Adapt to evolving business needs with innovative,
                          AI-driven solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">What We Offer</h4>
            <p className="des">
              Our comprehensive approach ensures seamless integration of Amazon
              Q Developer into your development lifecycle
            </p>
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Discovery Phase</h6>
                  <ul>
                    <li>
                      Stakeholder engagement, requirement analysis, and setup
                      planning.
                    </li>
                    {/* <li>
                      <strong>Deliverable:</strong> A detailed DR strategy
                      document.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Setup Phase</h6>
                  <ul>
                    <li>
                      Configuration of AWS services, Amazon Q Developer, and
                      CI/CD pipelines.
                    </li>
                    {/* <li>
                      <strong>Deliverable:</strong> Region and resource
                      allocation plan.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Training</h6>
                  <ul>
                    <li>
                      Workshops and hands-on sessions for your team to master
                      Amazon Q Developer.
                    </li>
                    {/* <li>
                      <strong>Deliverable:</strong> Fully functional replication
                      setup.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Pilot Phase</h6>
                  <ul>
                    <li>
                      Initial application module development to test AI-assisted
                      features.
                    </li>
                    {/* <li>
                      <strong>Deliverable:</strong> Verified DR test results.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Full Implementation</h6>
                  <ul>
                    <li>Scale and optimize your development projects.</li>
                    {/* <li>
                      <strong>Deliverable:</strong> Regular updates and
                      optimization plans.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Optimization</h6>
                  <ul>
                    <li>
                      Regular reviews and future scaling to maximize long-term
                      benefits.
                    </li>
                    {/* <li>
                      <strong>Deliverable:</strong> Regular updates and
                      optimization plans.
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Why Choose Cruxito?</h3>
                <p>
                  As an AWS Select Tier Partner, Cruxito offers unmatched
                  expertise in AWS-backed solutions. Our experienced team of
                  AWS-certified professionals ensures your success through every
                  phase of your application development journey.
                </p>
                <div className="how-it-works">
                  <h3>How It Works</h3>
                  <ol>
                    <li>
                      <strong>Duration:</strong> 10-12 weeks tailored to your
                      project complexity.
                    </li>
                    <li>
                      <strong>Engagement Model:</strong> Remote-first approach
                      ensuring flexibility and efficiency.
                    </li>
                    <li>
                      <strong>Support:</strong> Continuous monitoring,
                      performance optimization, and expert consultations.
                    </li>
                  </ol>
                </div>
              </div>
              <figure>
                <img
                  src={require("../images/Q_developer-Cruxito.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Transform Your Development Process</h3>
                <p>
                  Ready to revolutionize your application development with
                  AI-powered tools? Discover the future of software development
                  with Amazon Q Developer and Cruxito Tech Solutions.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <div className="schedule-info">
        <h4 className="title">Schedule a Free Consultation Today!</h4>
        <p>
          Let our experts guide you in unlocking the full potential of Amazon Q
          Developer for your business. Would you like to proceed with a
          particular design or layout idea for your webpage?
        </p>
      </div>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default GenAiDeveloper;
