import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function GenAiBusiness() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              Gen AI Business
            </label>
            <h4 className="mb-0">
              Empower Your Business with Amazon Q Business Solutions
            </h4>
            <p>Experience the Future of Business with Amazon Q</p>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      <strong>Cruxito Tech Solutions</strong> is proud to
                      introduce <strong>Amazon Q Business Solutions</strong>, a
                      groundbreaking Generative AI-powered service that helps
                      businesses transform operations, enhance decision-making,
                      and stay ahead of market trends.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/AI-business_banner.png")}
                      alt="Why Cruxito"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row w-100">
              <div className="col-lg-5 col-md-12">
                <h2>
                  As an <strong>AWS Select Tier Partner</strong>, Cruxito
                  ensures seamless integration and customization of Amazon Q
                  Business solutions tailored to your business needs.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Key Benefits</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Enhanced Decision-Making:</h6>
                        <p>
                          Leverage AI-driven insights for smarter, data-backed
                          decisions.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Streamlined Operations:</h6>
                        <p>
                          Automate tasks, optimize workflows, and reduce costs.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Personalized Engagement:</h6>
                        <p>
                          Deliver tailored customer experiences through
                          intelligent AI solutions.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>Boosted Efficiency:</h6>
                        <p>
                          Use predictive analytics to proactively address
                          challenges.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Scalable Solutions:</h6>
                        <p>
                          Seamlessly integrate with existing systems and scale
                          as your business grows.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Competitive Edge: </h6>
                        <p>
                          Stay ahead with cutting-edge Generative AI
                          technologies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Process</h4>
            <p className="des">
              We offer a structured, six-phase implementation plan for a smooth
              transition to Amazon Q Business Solutions:
            </p>
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Planning:</h6>
                  <ul>
                    <li>
                      Requirement gathering, solution design, and project
                      kickoff.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Setup</h6>
                  <ul>
                    <li>
                      Amazon Q environment setup and integration planning.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Development</h6>
                  <ul>
                    <li>
                      Customized solutions tailored to your unique business
                      needs.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Testing</h6>
                  <ul>
                    <li>
                      User Acceptance Testing (UAT) and AI model validation.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Deployment</h6>
                  <ul>
                    <li>
                      Final implementation, automation configuration, and user
                      training.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Optimization</h6>
                  <ul>
                    <li>Post-deployment support and solution fine-tuning.</li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="des">Estimated Duration: 10-15 weeks.</p>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Why Choose Cruxito?</h3>
                <ul>
                  <li>
                    <strong>AWS Expertise:</strong> Certified professionals
                    ensuring high-quality solutions.
                  </li>
                  <li>
                    <strong>Custom Solutions: </strong>Every implementation is
                    tailored to your specific needs.
                  </li>
                  <li>
                    <strong>Proven Methodology:</strong> Our structured approach
                    ensures timely delivery and optimized results.
                  </li>
                  <li>
                    <strong>Customer-Centric Support: </strong>Dedicated support
                    and training for your team.
                  </li>
                </ul>
              </div>
              <figure>
                <img
                  src={require("../images/AI-business_cruxito.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Take the Next Step</h3>
                <p>
                  Ready to unlock the full potential of Generative AI with
                  Amazon Q Business Solutions? Contact us today to discuss your
                  requirements and get started on your journey to innovation.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default GenAiBusiness;
