import React, { useState } from "react";
import cruxito from "../images/Cruxito_Logo.svg";

function Footer() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleGetGuide = (e) => {
    e.preventDefault();

    const URL = "https://cruxito.tech/blog/wp-json/custom/v1/subscribe";

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const data = {
      email,
      tags: ["Get Guide"], // Include the tag for categorization
    };

    // Send the email and tag to the backend
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setStatus("Success! Guide sent to your email.");
        } else {
          setStatus("Failed to subscribe. Please try again.");
        }
        setEmail(""); // Clear input
      })
      .catch(() => {
        setStatus("An error occurred. Please try again later.");
      });
  };


  return (
    <footer>
      <div className="container">
        <div className="footer-head">
          <div className="navigations">
            <a href="https://cruxito.tech/blog">Blog</a>
            <span>
              <img src={require("../images/aws.png")} alt="aws" />
            </span>
          </div>
          <a href="/" className="logo">
            <img src={cruxito} alt="cruxito" />
          </a>

          <div className="email_sec">
            <label>Get our 10 Steps to Success Guide</label>
            <div className="footer_input">
              <form onSubmit={handleGetGuide}>

             

            
                <input
                  type="email"
                  placeholder="Enter Your Email id"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit">Get the Guide</button>
              </form>
            </div>
            {status && <p className="status-message">{status}</p>}
          </div>
        </div>

        <div className="footer_bottom">
          <ul className="social_networks">
            <li>
              <a href="https://x.com/cruxitotech" target="_blank" rel="noopener noreferrer">
                <img src={require("../images/twitter.png")} alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/cruxito-tech-solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require("../images/linkedin.png")} alt="linkedin" />
              </a>
            </li>
          </ul>
          <ul className="social_networks wauto">
            <li>
              <a href="https://cruxito.tech/blog/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://cruxito.tech/blog/terms-of-use/">Terms of Use</a>
            </li>
            <li>
              <a href="https://cruxito.tech/blog/unsubscribe-form/">Unsubscribe</a>
            </li>
          </ul>
          <label>© 2024 Cruxito Tech Solutions, LLC</label>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
