import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function GenAiBot() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              {" "}
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              QnABot
            </label>
            <h4>Elevate Your Customer Experience with QnABot Services</h4>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      Transform the way your business engages with customers
                      using <strong>Cruxito's QnABot Services</strong>. With
                      advanced conversational AI capabilities, our service helps
                      you deploy intelligent chatbots powered by AWS.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/Qbot-banner.png")}
                      alt="Qbot"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row w-100">
              <div className="col-lg-5 col-md-12">
                <h2>
                  These chatbots are tailored to meet your specific business
                  needs, ensuring seamless and personalized customer
                  interactions.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Why Choose QnABot Services?</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Enhanced Customer Engagement</h6>
                        <p>
                          Deliver instant, precise responses to customer
                          queries, boosting satisfaction and efficiency.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Cost Efficiency:</h6>
                        <p>
                          Automate repetitive tasks, minimizing dependency on
                          manual support.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Scalability:</h6>
                        <p>
                          Seamlessly manage fluctuating customer interactions
                          with scalable architecture.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>Customizable Solutions:</h6>
                        <p>
                          Adapt responses and workflows to align with your
                          unique business goals.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Multi-Channel Integration:</h6>
                        <p>
                          Deploy the chatbot across web, mobile, and digital
                          platforms for consistent engagement.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Data-Driven Insights: </h6>
                        <p>
                          Leverage analytics to refine customer interactions and
                          improve service quality.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Proven Process</h4>
            <p className="des">
              We offer a structured, six-phase implementation plan for a smooth
              transition to Amazon Q Business Solutions:
            </p>
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Planning (Weeks 1-2)</h6>
                  <ul>
                    <li>
                      Gather requirements, define use cases, and design Q&A
                      workflows.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> Requirement document, use
                      case definitions, Q&A design flows.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Setup (Week 3)</h6>
                  <ul>
                    <li>
                      Deploy QnABot using AWS CloudFormation and validate AWS
                      environment.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> AWS setup validation,
                      QnABot deployment.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Integration (Weeks 4-5)</h6>
                  <ul>
                    <li>
                      Integrate backend systems (e.g., CRM, ERP) and configure
                      APIs.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> Backend systems
                      integration, API configuration reports.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Testing (Weeks 6-7):</h6>
                  <ul>
                    <li>
                      Conduct security, load, and user acceptance testing.
                      Iterate based on feedback.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> UAT reports, security, and
                      performance metrics.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Go-Live (Week 8)</h6>
                  <ul>
                    <li>
                      Launch the QnABot and monitor performance for a seamless
                      experience.
                    </li>
                    <li>
                      <strong>Deliverables: </strong>Live QnABot, initial
                      performance report.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Optimization (Weeks 9-10):</h6>
                  <ul>
                    <li>
                      Fine-tune responses and monitor interactions to ensure
                      peak performance.
                    </li>
                    <li>
                      <strong>Deliverables:</strong> Optimization report, final
                      documentation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Key Features</h3>
                <ul>
                  <li>
                    <strong>Robust Security:</strong> Built-in security measures
                    to ensure data protection.
                  </li>
                  <li>
                    <strong>AWS-Powered Innovation:</strong>Leverage the
                    reliability and scalability of AWS.
                  </li>
                  <li>
                    <strong>Expert Support:</strong> Managed by AWS-certified
                    professionals at every step.
                  </li>
                </ul>
              </div>
              <figure>
                <img
                  src={require("../images/why-choose-qbot.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Schedule a Free Consultation</h3>
                <p>
                  Ready to revolutionize your customer experience? Let’s discuss
                  how QnABot can empower your business.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default GenAiBot;
