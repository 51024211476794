import React from "react";
import Contact from "./Contact";
import homeIcon from "../images/home-icon.svg";
function DisasterRecovery() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              disaster
            </label>
            <h4>Safeguard Your Business with Seamless Disaster Recovery</h4>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      In an unpredictable world, ensuring your business
                      continuity is non-negotiable. Cruxito’s
                      <strong>Managed Disaster Recovery Solutions</strong>
                      leverage
                      <strong>AWS Elastic Disaster Recovery (AWS EDR)</strong>
                      to deliver a robust, cost-efficient, and secure solution
                      tailored to your needs.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img
                      src={require("../images/diaster-banner.png")}
                      alt="Why Cruxito"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <h2>
                  Our expertise ensures rapid recovery from outages, failures,
                  or disasters, empowering your business to stay resilient in
                  the face of unexpected disruptions.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>
                    Why Choose Cruxito’s Managed Disaster Recovery Solutions?
                  </h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Minimized Downtime</h6>
                        <p>
                          Achieve rapid recovery for critical applications to
                          reduce the impact of outages.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Cost Efficiency</h6>
                        <p>
                          Optimize spending with a pay-as-you-go model that
                          eliminates the need for duplicate infrastructure.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Scalability and Flexibility</h6>
                        <p>
                          Easily adapt to your evolving business needs with
                          scalable AWS solutions.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>End-to-End Support</h6>
                        <p>
                          Benefit from comprehensive guidance, from planning to
                          implementation and beyond.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Enhanced Compliance</h6>
                        <p>
                          Stay compliant with stringent regulatory standards
                          using AWS’s secure infrastructure.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Streamlined Testing</h6>
                        <p>
                          Conduct disaster recovery drills without disrupting
                          production systems.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Process</h4>
            <p className="des">
              Our managed service ensures a seamless experience, broken down
              into five key phases:
            </p>
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Assessment & Planning</h6>
                  <ul>
                    <li>
                      Identify critical workloads and set Recovery Time
                      Objectives (RTO) and Recovery Point Objectives (RPO).
                    </li>
                    <li>
                      <strong>Deliverable:</strong> A detailed DR strategy
                      document.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Architecture Design:</h6>
                  <ul>
                    <li>Craft a tailored disaster recovery architecture.</li>
                    <li>
                      <strong>Deliverable:</strong> Region and resource
                      allocation plan.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="process-flow">
                  <h6>Implementation</h6>
                  <ul>
                    <li>
                      Configure AWS Elastic Disaster Recovery with data
                      replication.
                    </li>
                    <li>
                      <strong>Deliverable:</strong> Fully functional replication
                      setup.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="process-flow">
                  <h6>Testing & Validation:</h6>
                  <ul>
                    <li>
                      Conduct rigorous failover and failback tests to ensure
                      RTO/RPO compliance.
                    </li>
                    <li>
                      <strong>Deliverable:</strong> Verified DR test results.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="process-flow">
                  <h6>Ongoing Support & Optimization:</h6>
                  <ul>
                    <li>
                      Continuous monitoring, reporting, and optimization for
                      enhanced performance.
                    </li>
                    <li>
                      <strong>Deliverable:</strong> Regular updates and
                      optimization plans.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Why Cruxito?</h3>
                <p>
                  With years of experience and deep AWS expertise, Cruxito is
                  committed to delivering disaster recovery solutions that align
                  with your unique business goals. Trust us to protect your
                  critical operations with industry-leading technology and
                  personalized support.
                </p>
              </div>
              <figure>
                <img
                  src={require("../images/diaster-cruxito.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Schedule Your Free Consultation Today</h3>
                <p>
                  Take the first step toward secure and reliable disaster
                  recovery. Contact us to schedule a free consultation and
                  discover how Cruxito can safeguard your business operations.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default DisasterRecovery;
