import React from "react";
import cruxito from "../images/Cruxito_Logo.svg";
import { HashLink } from "react-router-hash-link";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="header_cnt">
          <a href="/" className="logo">
            <img src={cruxito} alt="cruxito" />
          </a>
          <nav>
            <ul>
              <li> <span>
              <img src={require("../images/aws.png")} style={{'maxHeight':50}} alt="aws" />
            </span></li>
              <li>
                <a className="sub">
                  Services
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                        fill="#23262E"
                      ></path>{" "}
                    </g>
                  </svg>
                </a>
                <div className="submenu">
                  <div>
                    <h4>Storage</h4>
                    <ul>
                      <li>
                        <a href="/storage-migration">Storage Migration</a>
                      </li>
                      <li>
                        <a href="/managed-backup">​Managed Backup</a>
                      </li>
                      <li>
                        <a href="/managed-disaster-recovery">
                          ​Disaster Recovery
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4>Gen AI</h4>
                    <ul>
                      <li>
                        <a href="/gen-ai-developer">Gen AI Developer</a>
                      </li>
                      <li>
                        <a href="/gen-ai-business">​Gen AI Business</a>
                      </li>
                      <li>
                        <a href="/gen-ai-chatbot">​Gen AI Bot</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4>​Application Modernization</h4>
                    <ul>
                      {" "}
                      <li>
                        <a href="/application-modernization">Cloud Migration</a>
                      </li>
                      <li>
                        <a href="/application-modernization">
                          Product Development
                        </a>
                      </li>
                      <li>
                        <a href="/application-modernization">
                          Containerization
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href="https://cruxito.tech/blog" >
                  Blog
                </a>
              </li>
              <li>
                <a className="contact" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
export default Header;
