import React from "react";
import Contact from "./Contact";
import storageImg from "../images/storage.svg";
import homeIcon from "../images/home-icon.svg";
function StorageMigration() {
  return (
    <React.Fragment>
      <section className="service-banner">
        <div className="container">
          <div className="service-banner-cnt">
            <label className="service-label">
              <a href="/">
                <img src={homeIcon} alt="Cruxito" />
              </a>
              STORAGE
            </label>
            <h4>Seamless and Secure AWS Storage Migration</h4>
            <a href="/contact/schedule-consultation" className="primary-btn">
              Schedule a Free Consultation
            </a>
            <div className="banner-info">
              <div className="row w-100 align-center">
                <div className="col-md-7 col-sm-12">
                  <div className="service-banner-text">
                    <p>
                      At Cruxito Tech Solutions, we specialize in helping
                      businesses migrate their data securely, efficiently, and
                      reliably to the AWS Cloud. Leveraging AWS Transfer Family
                      for managed file transfers and AWS Snowball for
                      large-scale, offline data migration, we ensure a seamless
                      transition with minimal operational disruption.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <figure>
                    <img src={storageImg} alt="Cruxito" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-features">
        <div className="container">
          <div className="features-sec">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <h2>
                  Whether you’re transferring files securely over the network or
                  migrating massive datasets using physical devices, Cruxito
                  Tech Solutions offers end-to-end support tailored to your
                  business needs.
                </h2>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="features-cnt">
                  <h6>Why Choose Our AWS Storage Migration Services?</h6>
                  <div className="features-list">
                    <div className="list-item">
                      <span>01.</span>
                      <div className="features-info">
                        <h6>Fast and Efficient Migration</h6>
                        <p>
                          Speed up your data migration with advanced AWS
                          Transfer Family and AWS Snowball solutions.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>02.</span>
                      <div className="features-info">
                        <h6>Secure Data Handling</h6>
                        <p>
                          Benefit from industry-standard encryption and
                          compliance protocols for maximum data security.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>03.</span>
                      <div className="features-info">
                        <h6>Scalable Solutions</h6>
                        <p>
                          Adapt easily to growing business needs and varying
                          data sizes.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>04.</span>
                      <div className="features-info">
                        <h6>Cost-Effective Migration</h6>
                        <p>
                          Avoid costly infrastructure upgrades or third-party
                          software by leveraging AWS-native tools.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>05.</span>
                      <div className="features-info">
                        <h6>Minimal Downtime</h6>
                        <p>
                          Experience near-zero operational disruption during
                          migration.
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <span>06.</span>
                      <div className="features-info">
                        <h6>Comprehensive Support</h6>
                        <p>
                          Access expert guidance throughout the planning,
                          execution, and validation phases.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="process">
        <div className="container">
          <div className="process-cnt">
            <h4 className="title">Our Proven Migration Process</h4>
            {/* <p className="des">
              Our managed service ensures a seamless experience, broken down
              into five key phases:
            </p> */}
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Planning and Assessment</h6>
                  <ul>
                    <li>
                      Understand your current infrastructure and requirements.
                    </li>
                    <li>
                      Define project scope, risks, and success metrics.<br></br>
                      <strong>Deliverable:</strong> Migration strategy, risk
                      assessment, and detailed project plan.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Preparation</h6>
                  <ul>
                    <li>
                      Set up secure AWS Transfer Family endpoints and provision
                      Snowball devices.
                    </li>
                    <li>
                      Train your team and conduct pipeline tests.<br></br>
                      <strong>Deliverable:</strong> Configured systems and
                      validated migration process.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="process-flow">
                  <h6>Migration Execution</h6>
                  <ul>
                    <li>Migrate smaller datasets using AWS Transfer Family.</li>
                    <li>
                      Use AWS Snowball for bulk data transfers.<br></br>
                      <strong>Deliverable:</strong> Successfully migrated data
                      with performance and security audits.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col12 col-md-6">
                <div className="process-flow">
                  <h6>Post-Migration Validation</h6>
                  <ul>
                    <li>
                      Verify data integrity and optimize AWS storage
                      configurations.
                    </li>
                    <li>
                      Decommission legacy systems for a complete transition.
                      <br></br>
                      <strong>Deliverable:</strong> Post-migration validation
                      report and project handover.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-wrap">
        <div className="details-sec">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Why Cruxito?</h3>
                {/* <p>
                  With years of experience and deep AWS expertise, Cruxito is
                  committed to delivering disaster recovery solutions that align
                  with your unique business goals. Trust us to protect your
                  critical operations with industry-leading technology and
                  personalized support.
                </p> */}
                <ul>
                  <li>
                    <strong>AWS Expertise:</strong> Certified AWS professionals
                    with extensive experience in cloud migrations.
                  </li>
                  <li>
                    <strong>Global Reach:</strong> Migrate data across regions
                    or globally with AWS’s robust infrastructure.
                  </li>
                  <li>
                    <strong>Dedicated Support:</strong> Ongoing assistance to
                    ensure smooth operations.
                  </li>
                </ul>
              </div>
              <figure>
                <img
                  src={require("../images/why-cruxito.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="details-sec pad0">
          <div className="container">
            <div className="details-cnt">
              <div className="details-text">
                <h3>Ready to Migrate to AWS?</h3>
                <p>
                  Let Cruxito Tech Solutions help you make your cloud migration
                  journey seamless, secure, and stress-free.
                </p>
                <a href="/contact/schedule-consultation" className="primary-btn">
                  Schedule a Free Consultation
                </a>
              </div>
              <figure className="mt-60px">
                <img
                  src={require("../images/colsultation.png")}
                  alt="Why Cruxito"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default StorageMigration;
